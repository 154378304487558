



































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Stroage from "../../util/Storage"
import { UpDataType } from "../../Type/index"
import { GetMoney } from "../../Api/Basics/index"
import { UserStore } from "../../store/modules/User"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface MoneyType<T=string>{
    userId:T|number;
    token:T;
    MoneyIcon:T;
    NoMoneyIcon:T;
    data:UpDataType<number|T>
    loadingShow:boolean;

    handleUpData():void;
    handleError(data:string):void;
}

@Component({ name:"Money",components:{ HeadTop } })
export default class Money extends ZoomPage implements MoneyType{
    userId:string|number = ""
    token = ""
    MoneyIcon = require("../../assets/icon/Basics/Money.png");
    NoMoneyIcon = require("../../assets/icon/Basics/NoMoney.png");
    data:UpDataType<number|string> = {}
    loadingShow = true

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        let { data } = this.$route.query
        if( data.length > 1 ){
            this.loadingShow = false
            this.data = JSON.parse( data as string )
        }else{
            if( JSON.stringify(UserStore.GetMoneyData) !== "{}" ){
               this.loadingShow = false
               this.data = UserStore.GetMoneyData
            }else{
               this.handleUpData()
            }
        }
    }

    handleUpData(){
        GetMoney({
            userId:this.userId,
            token:this.token
        }).then( (res:any)=>{
            this.loadingShow = false
            if( res.message.code === "200" ){
                UserStore.SetMoneyData(res.data)
                this.data = res.data
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
